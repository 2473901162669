import { IConfiguration, IDefaultSettings } from "../interfaces/types"

const apiSettings = {
  baseUrl: "",
  apiKey: "",
  baseSettings: {
    "Content-Type": "application/json",
    accept: "application/json",
    "X-Api-Key": "",
  }
}

let UNIQUE_IDENTIFIER = ""
let tonality = `${process.env?.REACT_APP_TONALITY ?? "professional"}`

export const changeApiSettings = (baseUrl: string, apiKey: string) => {
  apiSettings.baseUrl = baseUrl
  apiSettings.apiKey = apiKey
  apiSettings.baseSettings = { ...apiSettings.baseSettings, "X-Api-Key": apiKey }
}

export const setTonality = (t: "friendly" | "professional") => {
  tonality = t
}

export const textToSpeech = async (text: any, modelName: string, T2S_URL?: string, T2S_KEY?: string) => {
  if (!text) throw new Error("Empty text")

  if (!T2S_URL || !T2S_KEY) {
    throw new Error("No T2S_URL or T2S_KEY provided")
  }

  const url = T2S_URL;

  return await fetch(url, {
    method: "POST",
    headers: {
      "X-Api-Key": T2S_KEY,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      textToSpeech:
        `<prosody rate="${process.env?.REACT_APP_T2S_RATE ?? "1.1"}" pitch="${process.env?.REACT_APP_T2S_PITCH ?? "-5%"
        }" volume="${process.env?.REACT_APP_T2S_VOLUME ?? "soft"}">` +
        text +
        "</prosody>",
      modelName: `${modelName}`,
      tone: tonality,
    }),
  }).then((r) => r.blob())
}

export const telemetry = async (data: {
  interaction_ai: number
  interaction_user: number
  duration: number
  audio: Blob
}) => {
  const formData = new FormData()
  formData.append("audio", data.audio, "audio.wav")
  return await fetch(
    `${apiSettings.baseUrl}/telemetry?interaction_ai=${data.interaction_ai}&interaction_user=${data.interaction_user}&duration=${data.duration}&session_id=${UNIQUE_IDENTIFIER}`,
    {
      headers: {
        "X-Api-Key": apiSettings.baseSettings["X-Api-Key"],
      },
      method: "POST",
      body: formData,
    },
  ).then((r) => r.text())
}

export const startChat = async (
  { agentId, isVoice, userName, promptId }:
    { agentId: string, isVoice?: boolean, userName?: string, promptId?: string },
) => {
  //if (!agentId) throw new Error("No agent ID provided")

  let url = apiSettings.baseUrl + `/conversation?agentId=${agentId}&isVoice=${isVoice}`;
  if (userName) url += `&userName=${userName}`
  if (promptId && promptId !== "undefined") url += `&promptId=${promptId}`

  return await fetch(
    url,
    {
      headers: { "X-Api-Key": apiSettings.baseSettings["X-Api-Key"] },
      method: "POST",
    },
  ).then((r) => r.json()).then(r => {
    UNIQUE_IDENTIFIER = r.externalId;
    return r as IDefaultSettings
  })
}

export const getConfiguration = () => {
  const isLocalhost = window.location.href.includes("localhost") || window.location.href.includes("127.0.0.1");
  const baseUrl = isLocalhost ? "http://localhost:7071/api/getConfig" : "/api/getConfig";
  const [p1, p2] = window.location.pathname.split("/").slice(1, 3);

  return fetch(baseUrl + `?n=${p1}&a=${p2}`, {
    method: "GET",
  }).then((r) => r.json()).then(r => r as IConfiguration)
}

export const insertMessageApi = async (text: string) => {
  if (!UNIQUE_IDENTIFIER) throw new Error("No conversation ID provided")

  const r = await fetch(apiSettings.baseUrl + `/conversation/${UNIQUE_IDENTIFIER}`, {
    headers: { ...apiSettings.baseSettings },
    method: "PUT",
    body: JSON.stringify({
      content: text,
    }),
  })
  return await r.json()
}
