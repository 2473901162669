export const applyConfiguration = (primaryColor: string, secondaryColor: string, title: string, bgColor: string | null = null) => {
	const styleElem = document.getElementById("style_per_org");
	const titleElem = document.querySelector("title");

	if (!styleElem) {
		throw new Error("Style per org element not found!");
	}

	if (styleElem && primaryColor && secondaryColor) {
		styleElem.innerHTML = `
			.bg-primary {
				--tw-bg-opacity: 1;
				background-color: ${primaryColor};
			}

			.bg-secondary {
				--tw-bg-opacity: 1;
				background-color: ${secondaryColor};
			}
		`;
	}

	if (titleElem) {
		titleElem.innerText = title;
	}

	if (bgColor) {
		document.querySelector("body")?.style.setProperty("background", `${bgColor}`);
	}
}