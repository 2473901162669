import {
  SpeechConfig,
  SpeechRecognizer,
  AudioConfig,
  ResultReason,
  SpeechSynthesizer,
} from "microsoft-cognitiveservices-speech-sdk"

export class SymantoMicrophoneAndSpeechService {
  private SPEECH_CONFIG = SpeechConfig.fromSubscription(
    "5d670901f9124722af54b48137604fa1",
    "northeurope",
  )

  audioContext: AudioContext | null = null
  audioChunks: Blob[] = []
  speech;
  lang;
  modelName;
  rate;
  pitch;
  volume;

  public mediaRecorder: MediaRecorder | null = null

  constructor(lang: string, modelName: string, rate: string, pitch: string = '-5%', volume: string = 'soft') {
    this.lang = lang
    this.modelName = modelName

    this.SPEECH_CONFIG.speechRecognitionLanguage = this.lang
    this.SPEECH_CONFIG.speechSynthesisVoiceName = this.modelName ?? "en-US-AriaNeural"

    this.speech = new SpeechRecognizer(this.SPEECH_CONFIG)
    this.rate = rate;
    this.pitch = pitch;
    this.volume = volume;
  }

  startRecognizer = async (): Promise<void> => {
    console.info(`[DEBUG] start continuous recognition - Language: ${this.lang}`)

    try {
      if (!this.audioContext) {
        this.audioContext = new AudioContext()
      }

      this.speech.startContinuousRecognitionAsync()
    } catch (error: any) {

      console.error("Error accessing microphone:", error)

      if (
        error.name === "NotAllowedError" ||
        error.name === "PermissionDeniedError"
      ) {
        alert("Microphone access is blocked. Please allow microphone access and try again.")
      }
    }
  }

  stopRecognizer = (): void => {
    console.info(`[DEBUG] stop continuous recognition - Language: ${this.lang}`)
    this.speech.stopContinuousRecognitionAsync(() => {
      if (this.audioContext) {
        this.audioContext.close()
        this.audioContext = null
      }

      this.mediaRecorder = null
    })
  }

  public pauseDetected = (): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      try {
        console.info(`[DEBUG] Pause detected`)
        if (this.mediaRecorder && this.mediaRecorder.state !== "inactive") {
          this.mediaRecorder.stop()
          this.mediaRecorder.onstop = () => {
            const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" })
            this.audioChunks = []
            if (this.mediaRecorder) this.mediaRecorder.start() // Restart recording after playing audio
            resolve(audioBlob)
          }
        }
      } catch (e) {
        reject(e)
      }
    })
  }

  public synthesizeSpeechToAudio = async (text: string): Promise<number> => {
    const audioConfig = AudioConfig.fromDefaultSpeakerOutput()
    const synthesizer = new SpeechSynthesizer(this.SPEECH_CONFIG, audioConfig)

    const ssml = `
      <speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" 
      xml:lang="${this.lang}">
        <voice name="${this.modelName}">
          <prosody 
            rate="${this?.rate ?? "1.1"}" 
            pitch="${this.pitch ?? "-5%"}" 
            volume="${this.volume ?? "soft"}">  
              <lang xml:lang="${this.lang}">
                ${text.replaceAll("\\", "")} 
              </lang>
          </prosody>
        </voice>
      </speak>
      `
    return new Promise((resolve, reject) => {
      synthesizer.speakSsmlAsync(
        ssml,
        (result) => {
          if (result.reason === ResultReason.SynthesizingAudioCompleted) {

            if (
              this.mediaRecorder &&
              this.mediaRecorder.state === "recording"
            ) {
              this.mediaRecorder.stop()
            }

            resolve(result.audioDuration / 10000)

          } else {

            console.error("Speech synthesis failed:", result.errorDetails)
            reject(result.errorDetails)

          }
          synthesizer.close()
        },
        (error) => {

          console.error("Error during speech synthesis:", error)
          synthesizer.close()
          reject(error)

        },
      )
    })
  }
}
