import React from "react"
import { useConfiguration } from "../../hooks"

export const LoadingWrapper = ({ children }: { children: React.ReactNode }) => {
	const { configurationLoading } = useConfiguration()

	if (configurationLoading) return <></>

	return <>{children}</>
}
