import { useState, useEffect } from "react"
import { IConfiguration } from "../components/interfaces/types"
import { changeApiSettings, getConfiguration } from "../components/services/chat.service"



export const useConfiguration = () => {
	const [configuration, setConfiguration] = useState<IConfiguration>()
	const [configurationLoading, setConfigurationLoading] = useState(true)

	useEffect(() => {
		getConfiguration()
			.then((result) => {
				changeApiSettings(result.BASE_API_URL, result.API_KEY)
				setConfiguration(result)
			})
			.catch(console.error)
			.finally(() => setConfigurationLoading(false))
	}, [])

	return { configuration, configurationLoading }
}