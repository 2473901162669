// import "./sentry.js"
import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"

import { DigitalFriend } from "./components/digitalfriend/DigitalFriend"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { LoadingWrapper } from "./components/digitalfriend/LoadingWrapper"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route
					path="*"
					element={
						<LoadingWrapper>
							<DigitalFriend />
						</LoadingWrapper>
					}
				/>
			</Routes>
		</BrowserRouter>
	</React.StrictMode>,
)
