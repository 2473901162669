import React, { useEffect, useMemo, useRef, useState } from "react"
import { Message } from "./Message"
import { IMessage, useChatState } from "../state/ChatState"
import { SymantoMicrophoneAndSpeechService } from "../services/symantoMicrophone"
import {
	insertMessageApi,
	startChat,
	textToSpeech,
} from "../services/chat.service"
import { uuidv4 } from "../services/localStorage.service"
import { MicrophoneActions } from "./MicrophoneActions"
import { useConfiguration, useWindowDimensions } from "../../hooks"
import { IDefaultSettings } from "../interfaces/types"
import { applyConfiguration } from "../helpers/applyConfiguration"

export const DigitalFriend = () => {
	const chatState = useChatState() // global state
	const { configuration } = useConfiguration()
	const messages = chatState.chatMessages

	const [conversationId, setConversationId] = useState<string>("")
	const [initApproved, setInitApproved] = useState(false)
	const [chatEnded, setChatEnded] = useState(false)
	const [ttsQueue, setTtsQueue] = useState<string[]>([]) //? needed if more then 1 tts exists.
	const [isAudioPlaying, setIsAudioPlaying] = useState(false) //? flag for assistant talking back to you
	const [hasUserAction, setHasUserAction] = useState<boolean | null>(null)
	const [audio, setAudio] = useState<HTMLAudioElement | null>(null)
	const [userMessage, setUserMessage] = useState<string>("")
	const [isMicPausedByUser, setIsMicPausedByUser] = useState(false)
	const [isInitializing, setInitializing] = useState(false)
	const microphoneRef = useRef<SymantoMicrophoneAndSpeechService | undefined>()

	const enabledTextToSpeech = configuration?.ENABLE_TEXT_TO_SPEECH == "true"
	const isVoiceChat = configuration?.IS_VOICE_CHAT == "true"

	const productLogoUrl =
		configuration?.PRODUCT_LOGO_URL ||
		"https://symantopublic.blob.core.windows.net/logos/symanto.svg"
	const productChatLogoUrl =
		configuration?.PRODUCT_CHAT_LOGO_URL ||
		"https://symantopublic.blob.core.windows.net/logos/symanto.svg"

	const homeTitles = configuration?.HOME_TITLES?.split("|")
	const inCallLabels = configuration?.IN_CALL_LABELS?.split("|")
	const endCallLabels = configuration?.END_CALL_LABELS?.split("|")
	const keyboardLabels = configuration?.KEYBOARD_LABELS?.split("|")
	const chatSuggestions = configuration?.CHAT_SUGGESTIONS?.split("|")
	const connectingMessages = configuration?.CONNECTING_MESSAGES?.split("|")

	const [languageIndex, setLanguageIndex] = useState(0)

	const chatSuggestionsByLanguage = useMemo(
		() => chatSuggestions?.[languageIndex]?.split(";"),
		[chatSuggestions, languageIndex],
	)

	const [callDuration, setCallDuration] = useState(0)
	const callDurationRef = useRef(callDuration)
	const intervalRef = useRef<NodeJS.Timeout | null>(null)

	const { width: screenWidth } = useWindowDimensions()

	const bindKeyPress = (event: KeyboardEvent) => {
		if (event.code === "KeyM") {
			if (chatState.isMicActive === true) {
				stopRecognizing()
			} else {
				startRecognizing()
			}
		}
	}

	applyConfiguration(
		String(configuration?.PRIMARY_COLOR),
		String(configuration?.SECONDARY_COLOR),
		String(configuration?.TITLE),
		configuration?.BACKGROUND_COLOR,
	)

	useEffect(() => {
		if (isVoiceChat) document.addEventListener("keydown", bindKeyPress)
		return () => {
			if (isVoiceChat) document.removeEventListener("keydown", bindKeyPress)
		}
	}, [chatState.isMicActive])

	useEffect(() => {
		localStorage.setItem("isAudioPlaying", isAudioPlaying.toString())
	}, [isAudioPlaying])

	useEffect(() => {
		return () => clearInterval(intervalRef.current!)
	}, [])

	useEffect(() => {
		if (callDuration) {
			callDurationRef.current = callDuration
		}
	}, [callDuration])

	const startDuration = () => {
		intervalRef.current = setInterval(() => {
			setCallDuration((prev) => prev + 1)
		}, 1000)
	}

	const initChat = (index: number) => {
		setLanguageIndex(index)
		setInitApproved(true)
		setInitializing(true)

		const agentId = String(configuration?.AGENT_IDS.split("|")?.[index])
		const promptId = configuration?.PROMPT_ID

		startChat({ agentId, isVoice: isVoiceChat, promptId })
			.then((r: IDefaultSettings) => {
				const { externalId, messages } = r

				const mic = new SymantoMicrophoneAndSpeechService(
					r.languageCode,
					r.voiceIdentifier,
					String(r.voiceSpeed),
					configuration?.T2S_PITCH,
					configuration?.T2S_VOLUME,
				)

				microphoneRef.current = mic
				setConversationId(externalId)

				const { lastMessage } = r
				const message: IMessage = {
					id: uuidv4(),
					conversationId,
					messageAuthor: 0,
					date: new Date().toISOString(),
					finalMessage: true,
					text: lastMessage.content,
					time: isVoiceChat ? "00:01" : undefined,
				}

				chatState.appendChatMessage(message)

				textToSpeechPlay(messages[0].content)

				startDuration()
				setInitializing(false)
			})
			.catch((e) => {
				console.error(e)
				if (isVoiceChat) {
					stopRecognizing()
				}
				setInitializing(false)
			})
	}

	const analyzeMessage = async (text: string) => {
		console.log("Analyzing message: ", text)
		if (!text) return

		if (!isVoiceChat) {
			const message: IMessage = {
				id: uuidv4(),
				text: text,
				messageAuthor: 1,
				date: new Date().toISOString(),
				conversationId,
				finalMessage: true,
			}

			chatState.appendChatMessage(message)
		}

		try {
			let _responseMessage: IMessage = {
				id: uuidv4(),
				text: "",
				messageAuthor: 0,
				date: new Date().toISOString(),
				conversationId,
				finalMessage: false,
			}

			if (isVoiceChat) {
				const currentCallDuration = callDurationRef.current
				const timeFormatted = formatTime(currentCallDuration)

				_responseMessage = {
					..._responseMessage,
					time: timeFormatted,
				}
			}

			chatState.appendChatMessage(_responseMessage)

			const response = await insertMessageApi(text)
			if (response) {
				_responseMessage.text = response.text
				_responseMessage.finalMessage = true

				if (isVoiceChat) {
					const currentCallDuration = callDurationRef.current
					const timeFormatted = formatTime(currentCallDuration)

					_responseMessage = {
						..._responseMessage,
						time: timeFormatted,
					}
				}

				chatState.updateMessage(_responseMessage)

				textToSpeechPlay(response.text)

				if (response.stopped === true) {
					stopRecognizing()
				}
			} else {
				console.log("No response from server")
			}
		} catch (error) {
			console.error(error)
		}
	}

	const getIsAudioPlaying = () => {
		return localStorage.getItem("isAudioPlaying") == "true"
	}

	const startRecognizing = async () => {
		await new Promise((resolve) => setTimeout(resolve, 50))

		if (chatState.isMicActive === true) {
			console.info("Open mic already")
			return
		}

		chatState.setIsMicActive(true)

		microphoneRef!.current!.startRecognizer()

		let chatMessage: IMessage = {
			id: uuidv4(),
			conversationId,
			text: "",
			messageAuthor: 1,
			date: new Date().toISOString(),
			finalMessage: false,
		}

		let intervalForSelfStop: NodeJS.Timeout | null = null

		microphoneRef!.current!.speech.recognized = (rec, data) => {
			try {
				const res = data.result

				if (!res || res?.text?.length <= 2 || res?.text == undefined) return

				if (getIsAudioPlaying() === true) {
					console.info("Skipping s2t -> audio is playing in bg")
					return
				}

				console.info(`Recognized: ${res.text}`)

				chatMessage.text += " " + res.text

				if (isVoiceChat) {
					const currentCallDuration = callDurationRef.current
					const timeFormatted = formatTime(currentCallDuration)

					chatMessage = {
						...chatMessage,
						time: timeFormatted,
					}
				}

				chatState.updateMessage(chatMessage)

				if (intervalForSelfStop) {
					clearTimeout(intervalForSelfStop)
				}

				let timeout_duration = 5000
				try {
					timeout_duration = Number(configuration?.MIC_TIMEOUT_AFTER_SPEECH)
					if (isNaN(timeout_duration)) {
						timeout_duration = 5000
					}
					// eslint-disable-next-line
				} catch (e) {
					timeout_duration = 5000
				}

				intervalForSelfStop = setTimeout(() => {
					chatMessage = sendMessageForAnalysis(chatMessage)
					intervalForSelfStop = null
					stopRecognizing()
				}, timeout_duration)
			} catch (error) {
				console.error(error)
			}
		}

		// eslint-disable-next-line
		microphoneRef!.current!.speech.speechEndDetected = (rec, data) => {
			if (chatMessage.text.length <= 1) {
				return
			}

			chatMessage.finalMessage = true

			if (isVoiceChat) {
				const currentCallDuration = callDurationRef.current
				const timeFormatted = formatTime(currentCallDuration)

				chatMessage = {
					...chatMessage,
					time: timeFormatted,
				}
			}

			chatState.updateMessage(chatMessage)

			analyzeMessage(chatMessage.text)
			stopRecognizing()
			intervalForSelfStop = null
			chatMessage = {
				id: uuidv4(),
				conversationId,
				text: "",
				messageAuthor: 1,
				date: new Date().toISOString(),
				finalMessage: false,
			}
		}
		chatState.setIsMicActive(true)
	}

	const sendMessageForAnalysis = (msg: IMessage): IMessage => {
		analyzeMessage(msg.text)
		msg.finalMessage = true

		if (isVoiceChat) {
			const currentCallDuration = callDurationRef.current
			const timeFormatted = formatTime(currentCallDuration)

			msg = {
				...msg,
				time: timeFormatted,
			}
		}

		chatState.updateMessage(msg)
		return {
			id: uuidv4(),
			conversationId,
			text: "",
			messageAuthor: 1,
			date: new Date().toISOString(),
			finalMessage: false,
		}
	}

	const stopAudioPlaybackAndClearQueue = () => {
		try {
			if (audio) {
				audio.pause()
				audio.src = ""
			}
		} catch (e) {
			console.info("Unable to stop audio playback", e)
		}
		setTtsQueue([])
		setIsAudioPlaying(false)
	}

	const stopRecognizing = (isPausedByUser: boolean = false) => {
		setIsMicPausedByUser(isPausedByUser)
		if (chatState.isMicActive === true) {
			stopAudioPlaybackAndClearQueue()
		}

		setChatEnded(true)

		chatState.setIsMicActive(false)

		microphoneRef?.current?.stopRecognizer()
		microphoneRef?.current?.speech.stopContinuousRecognitionAsync()
	}

	const textToSpeechPlay = (text?: string) => {
		if (enabledTextToSpeech === false) return
		if (text == undefined && ttsQueue.length <= 0) return

		const _navigator = window.navigator as any

		if (hasUserAction === false) setHasUserAction(true)

		if (_navigator?.userActivation?.hasBeenActive === false) {
			if (text) setTtsQueue([...ttsQueue, text])
			setHasUserAction(false) // this will reinvoke this call.
			console.info("User has no action on session, showing modal", { text })
			return
		}

		if (isAudioPlaying === true) {
			console.info(
				`Another sound is currently playing, setting this (${text}) request in queue`,
			)
			if (text) setTtsQueue([...ttsQueue, text])
			return
		}

		if (!text && ttsQueue.length >= 1) {
			const _t = ttsQueue.shift()
			text = _t
			setTtsQueue(ttsQueue.slice(1, ttsQueue.length))
		}

		if (configuration?.USE_MICROSOFT_FOR_T2S == "true") {
			if (!text) return

			setIsAudioPlaying(true)
			microphoneRef!
				.current!.synthesizeSpeechToAudio(text)
				.then((timeUntilComplete) => {
					const startMicBeforeSpeechEnds: number = Number(
						process.env?.START_microphoneRefROPHONE_MS_BEFORE_SPEECH_ENDS ??
							750,
					)

					setTimeout(() => {
						if (ttsQueue.length <= 0) setIsAudioPlaying(false)
						else textToSpeechPlay() // this will call tts with next in line queued text

						// Restart recording after audio playback is complete
						if (microphoneRef.current?.mediaRecorder?.state !== "recording") {
							microphoneRef!.current!.mediaRecorder?.start()
						}

						if (isVoiceChat && isMicPausedByUser === false) startRecognizing()
					}, timeUntilComplete - startMicBeforeSpeechEnds)
					//? Playing audio is handled by the service itself
				})
				.catch((e) => {
					console.error(e)
					setIsAudioPlaying(false)
				})
		} else {
			textToSpeech(
				text,
				chatState.modelName,
				configuration?.T2S_URL,
				configuration?.T2S_KEY,
			)
				.then((s) => {
					setIsAudioPlaying(true)
					const audio = document.querySelector("#audio") as HTMLAudioElement
					setAudio(audio)

					if (microphoneRef?.current?.mediaRecorder?.state === "recording") {
						microphoneRef.current.mediaRecorder.stop()
					}

					if (audio) {
						try {
							const objectUrl = URL.createObjectURL(s)
							audio.src = objectUrl
							audio.onload = function () {
								// Release resource when it's loaded
								URL.revokeObjectURL(objectUrl)
							}
							audio.onended = function () {
								if (ttsQueue.length <= 0) setIsAudioPlaying(false)
								else textToSpeechPlay() // this will call tts with next in line queued text

								// Restart recording after audio playback is complete
								if (
									microphoneRef?.current?.mediaRecorder &&
									microphoneRef?.current?.mediaRecorder.state !== "recording"
								) {
									microphoneRef?.current?.mediaRecorder.start()
								}

								if (isVoiceChat && isMicPausedByUser === false)
									startRecognizing()
							}
							audio.play()
						} catch (error) {
							console.log(error)
							setIsAudioPlaying(false)
						}
					}
				})
				.catch((e) => {
					console.error(e)
					setIsAudioPlaying(false)
				})
		}
	}

	const sendMessage = (event: any) => {
		event.preventDefault()
		if (chatEnded === true) return

		const _message = `${userMessage}`
		analyzeMessage(_message)

		setUserMessage("")
	}

	const formatTime = (seconds: number): string => {
		const minutes = Math.floor(seconds / 60)
		const remainingSeconds = seconds % 60
		return `${String(minutes).padStart(2, "0")}:${String(
			remainingSeconds,
		).padStart(2, "0")}`
	}

	const onEndCallClick = () => {
		window.location.reload()
	}

	const mapFontSize = (index: number) => {
		let sizeMult = 10

		if (screenWidth < 768) {
			sizeMult = 6
		}

		if (index < 5) {
			return sizeMult * (5 - index)
		}

		return 8
	}

	const onSuggestionClick = (suggestion: string) => {
		const messageToAnalyze = `${suggestion}`
		analyzeMessage(messageToAnalyze)
	}

	return (
		<div className="min-h-[100vh] max-h-[100vh] flex overflow-y-auto">
			<div className="flex flex-1 flex-col items-center p-4 overflow-y-auto">
				{isVoiceChat && initApproved && (
					<div className="absolute bottom-0 left-0 mb-0 ml-3 z-10">
						<MicrophoneActions
							isActive={chatState.isMicActive}
							onPlay={() => startRecognizing()}
							onStop={() => stopRecognizing(true)}
						/>
					</div>
				)}

				{initApproved && (
					<div className="w-full max-w-[900px] flex flex-col flex-1 overflow-y-auto">
						{isInitializing && (
							<div className="flex w-full mt-1 md:mt-4 gap-3 items-center">
								{!!productChatLogoUrl && (
									<img
										src={productChatLogoUrl}
										className="w-full max-w-[50px] max-h-[50px] md:max-w-[60px] md:max-h-[60px] object-contain"
									/>
								)}
								<p className="text-sm md:text-base">
									{connectingMessages?.[languageIndex]}
								</p>
							</div>
						)}

						{!isInitializing && (
							<>
								<div className="flex items-center justify-center mb-4 md:mb-6">
									{isVoiceChat && (
										<p className="text-sm md:text-base">
											{inCallLabels?.[languageIndex]?.replace(
												"{{time}}",
												formatTime(callDuration),
											)}
											.
										</p>
									)}
									<p
										className="text-sm md:text-base underline ml-2 cursor-pointer"
										onClick={onEndCallClick}
									>
										{endCallLabels?.[languageIndex]}
									</p>
								</div>
								<div
									id="chat"
									className="flex flex-1 flex-col gap-2 md:gap-6 overflow-y-auto"
								>
									{messages?.map((message, index) => {
										return <Message key={index} message={message} />
									})}

									{messages?.length === 1 &&
										!!chatSuggestionsByLanguage?.length && (
											<div className="flex flex-1 items-end justify-center">
												<div className="w-full flex md:px-[60px]">
													<div className="w-full md:px-3 flex flex-col">
														<div className="w-full flex items-center gap-2 md:gap-4">
															{chatSuggestionsByLanguage.map(
																(suggestion, index) => (
																	<div
																		key={index}
																		className="flex flex-col flex-1 border border-gray-200 rounded-lg h-full p-2 md:p-4 cursor-pointer hover:bg-white hover:shadow-md"
																		onClick={() =>
																			onSuggestionClick(suggestion)
																		}
																	>
																		<img
																			src="core-lightbulb.svg"
																			className="w-[18px] h-[18px] md:w-[24px] md:h-[24px]"
																		/>
																		<p className="mt-2 word-break-all text-sm md:text-base">
																			{suggestion}
																		</p>
																	</div>
																),
															)}
														</div>
													</div>
												</div>
											</div>
										)}
								</div>
								{!isVoiceChat && (
									<div className="w-full mt-3 md:mt-5 p-0.5 flex">
										<div className="w-full flex md:px-[60px]">
											<div className="w-full md:px-3 flex flex-col">
												<form onSubmit={(event) => sendMessage(event)}>
													<div className="flex flex-1 relative w-full">
														<input
															type="text"
															className="block w-full p-3 md:p-5 text-sm md:text-base text-gray-900 rounded-lg bg-white shadow-sm focus:primary pr-14 md:pr-20"
															placeholder={`${
																keyboardLabels?.[languageIndex] ?? "Message"
															}`}
															value={userMessage}
															onChange={(e) => setUserMessage(e.target.value)}
															autoComplete="off"
															required
														/>
														{chatEnded === false && (
															<button
																type="submit"
																disabled={!userMessage?.length}
																className="absolute end-1 bottom-[6px] md:end-2 md:bottom-2.5 px-2 py-1 md:px-3 md:py-2 rounded-lg bg-transparent hover:shadow-xl disabled:opacity-20 disabled:pointer-events-none"
															>
																<img src="ic_send.svg" />
															</button>
														)}
													</div>
												</form>
											</div>
										</div>
									</div>
								)}
							</>
						)}
					</div>
				)}

				{!initApproved && (
					<div className="flex flex-col flex-1 w-full align-middle justify-center backdrop-blur-sm items-center">
						{!!productLogoUrl && (
							<img
								src={productLogoUrl}
								className="h-full max-h-[40px] md:max-h-[60px] object-contain mb-2"
							/>
						)}

						{homeTitles?.map((title, index) => (
							<p
								style={{ fontSize: mapFontSize(index) }}
								key={index}
								className={`text-center mt-2 md:mt-4`}
							>
								{title}.
							</p>
						))}

						<div className="flex flex-row flex-wrap items-center justify-center gap-4 md:gap-8 mt-14">
							{configuration?.BUTTONS.split("|").map((button, index) => (
								<button
									key={index}
									onClick={() => initChat(index)}
									className="bg-primary hover:bg-opacity-80 text-white font-bold py-2 px-4 md:py-3 md:px-6 rounded-lg md:rounded-xl text-sm md:text-base "
								>
									{button}
								</button>
							))}
						</div>
					</div>
				)}

				<div className="flex items-center justify-center mt-3 md:mt-6">
					<img
						src="/powered-by-symanto-logo.svg"
						className="max-h-[26px] md:max-h-[60px]"
						alt="Logo"
					/>
				</div>
			</div>
		</div>
	)
}
